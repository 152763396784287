import React from 'react'
import Layout from '../../../components/EN/LayoutEN'
import PageHeader from '../../../components/PageHeader/PageHeader'
import '../../../components/ParagraphUnderPhoto/styles.css'
import Accordion from '../../../components/Accordion/Accordion'
import AccordionTemplate from '../../../components/Services/AccordionTemplate'
import Index from '../../../../content/accordion/en/sluzby'
import { graphql } from 'gatsby'
const Sluzby = ({ data: { page } }) => {
  const { image, title } = page.frontmatter
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <PageHeader title={title} backgroundImage={image.childImageSharp.fluid} />
      <div className="Paragraph--Wrapper container">
        <p>
          Let the real professionals in the field advise you. We will help you
          to find your way through the tax and accounting legislation and
          relieve you of your tax and accounting agenda obligations. All the
          tasks are carried out in professional and efficient manner by our
          experts with minimum time input from your side. We offer one-off
          consultation as well as a long-term cooperation.
        </p>
        <p>
          Our certified tax advisors and qualified accountants with many-years’
          experience will be at your disposal. Every member of our team has
          their own specialization which (s)he is focused on and improving in so
          that we can offer high-quality services within the whole tax and
          accounting field.{' '}
        </p>
      </div>
      <Accordion
        className="container"
        items={[
          {
            title: Index.dane.title,
            description: <AccordionTemplate {...Index.dane} />,
          },
          {
            title: Index.mzdy.title,
            description: <AccordionTemplate {...Index.mzdy} />,
          },
          {
            title: Index.zahranici.title,
            description: <AccordionTemplate {...Index.zahranici} />,
          },
          {
            title: Index.firemniPoradenstvi.title,
            description: <AccordionTemplate {...Index.firemniPoradenstvi} />,
          },
          {
            title: Index.ucetnictvi.title,
            description: <AccordionTemplate {...Index.ucetnictvi} />,
          },
          {
            title: Index.znaleckePosudky.title,
            description: <AccordionTemplate {...Index.znaleckePosudky} />,
          },
        ]}
      />
    </Layout>
  )
}

export default Sluzby

export const pageQuery = graphql`
  query SluzbyEN {
    page: markdownRemark(frontmatter: { slug: { eq: "services" } }) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
