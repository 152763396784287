import React from 'react'
import './Footer.css'

export default () => (
  <footer className="Footer">
    <div className="container">
      <div className="Footer--Header" id="contact">
        <a
          href="https://cz.linkedin.com/organization-guest/company/ek-partners-s.r.o."
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
        >
          <img
            className="Footer--Social--LI"
            src="/images/homepage/linkedIn.png"
            alt="LinkedIn"
          />
        </a>
        <a
          href="https://www.facebook.com/EKPporadci/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <img
            className="Footer--Social--FB"
            src="/images/homepage/facebook.png"
            alt="Facebook"
          />
        </a>
      </div>
      <div className="Footer--Content">
        <h2>Branches</h2>
        <div className="row align-items-center justify-content-center Footer--Map--Wrap">
          <div className="col-lg-3">
            <div className="Footer--City">Praha:</div>
            <div>
              <a
                href="http://maps.google.com/?q=EKP Advisory, s.r.o., Senovážné náměstí, Praha 1-Nové Město"
                target="_blank"
                rel="noopener noreferrer"
              >
                Senovážné náměstí 8, 110 00 <br />
                Praha 1 <br />
              </a>
              <a href="tel:+420273139330">Tel: +420 273 139 330</a>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              className="Footer--Map"
              src="/images/homepage/map.png"
              alt="map"
            />
          </div>
          <div className="col-lg-3">
            <div className="Footer--City">Jihlava:</div>
            <div>
              <a
                href="http://maps.google.com/?q=EKP Advisory, s.r.o., Kosmákova, Jihlava"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telečská 1724/13, 586 01 <br />
                Jihlava <br />
              </a>
              <a href="tel:+420560000086">Tel: +420 560 000 086</a>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="Footer--City">Svitavy:</div>
            <div>
              <a href="" target="_blank" rel="noopener noreferrer">
                Milady Horákové 357/4, 568 02 <br />
                Svitavy <br />
              </a>
              <a href="tel:+420560000086">Tel: +420 560 000 086</a>
            </div>
          </div>
        </div>
        <p className="Footer--Info">
          © EKP Advisory s.r.o., Sídlo: Senovážné náměstí 992/8, 110 00 Praha 1,
          Česká republika, IČ: 04599624, DIČ: CZ04599624, zapsaná v obchodním
          rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 250538
        </p>
        <div className="Footer--Underline" />
      </div>
    </div>
  </footer>
)
