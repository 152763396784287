const topicSlug = {
    dane: 'taxes/',
    mzdy: 'payroll-services/',
    zahranici: 'abroad/',
    firemniPoradenstvi: 'firm-advisory/',
    ucetnictvi: 'accounting/',
    znaleckePosudky: 'expert-opinions/'
  }
  
  export const Index = {
    dane: {
      title: 'Taxes',
      slug: topicSlug.dane,
      description: `Do you need to consult tax impact of the planned transactions, report a Tax return or VAT Control Statement, be represented in communication with Tax Office or are you dealing with a different issue related to taxes? Feel free to contact us. We will help you with both direct and indirect taxes. We also provide quick consultations on the phone. Transfer pricing analysis are surely offered as well.`,
      moreEN: true
    },
    mzdy: {
      title: 'Payroll Services',
      slug: topicSlug.mzdy,
      description: `Keeping the personnel agenda is not only very demanding from administrative point of view but also bears the risk that employees may find out the salary levels of each other. Therefore, not only from this perspective it is worth it to have the payroll accounting or the payroll evidence outsourced. We can offer a maximum confidentiality, reliability and ability to meet the agreed deadlines. We constantly follow the developments of the legislation for you to be sure that the payroll services are carried out in accordance with most recent regulations. `,
      moreEN: true 
    },
    zahranici: {
      title: 'Abroad',
      slug: topicSlug.zahranici,
      description: `Are your business partners abroad companies or even firms from the countries outside of the EU? In that case you must deal with VAT and eventually the customs duty as well. We can also help you with data proceeding for Intrastat. Do not hesitate to contact us for further information.`,
      moreEN: true
    },
    firemniPoradenstvi: {
      title: 'Firm Advisory',
      slug: topicSlug.firemniPoradenstvi,
      description: `Are you about to begin with transformation, liquidation or restructuring of your business? Handle the changes in time with the help of our experts. You will have the assurance that everything is processed correctly within the law and with minimum negative tax impacts. Furthermore, we specialize in implementing the ERP systems  as well as in searching and testing candidates for economic positions for your company. `,
      moreEN: true
    },
    ucetnictvi: {
      title: 'Accounting',
      slug: topicSlug.ucetnictvi,
      description:
        `Does keeping the books mean a great burden or are you not sure about its correctness? We can relieve you of your duties and help to set up all the procedures of your accounting. We will suggest and implement reporting and (or) controlling systems in accordance with your ideas and requirements. With the right use such system can lead to well-managed costs, projects, contracts etc. We will provide you with a great economic management of your company. `,
        moreEN: true

      },
    znaleckePosudky: {
      title: 'Expert Opinions',
      slug: topicSlug.znaleckePosudky,
      description:
        `Are you dealing with a civil dispute or even criminal case and need an expert assessment in the field of taxes or accounting? Let us work out the expert opinion. Our opinion or statement is an objective and independent assessment that can be used as an evidence in case of need. It is very common that the dispute parties stand a different opinion. In such cases the expert statement can help to move the case further.`,
        moreEN: true
      }
  }
  export default Index
  